import Vue from 'vue';

export default {
    name: 'Layout',
    props: {
        name: {
            type: String,
            default: 'Default'
        }
    },
    created() {
        // Check if the layout component
        // has already been registered.
        if (!Vue.options.components[this.name]) {
            Vue.component(
                this.name,
                () => import(`../layouts/${this.name}.vue`),
            );
        }

        this.$parent.$emit('update:layout', this.name);
    },
    render() {
        if(this.$slots.default && this.$slots.default.length > 1){
            throw new Error("You can have only one top-level child element in each Layout");
        }
        return this.$slots.default[0];
    }
};
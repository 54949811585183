<template>
    <Layout>
        <div>
            <h1 class="text-h4 font-weight-medium">
                Witaj w panelu administracyjnym
            </h1>
            <p class="grey--text">
                Wybierz pozycję z menu po lewej stronie i rozpocznij zarządzanie swoim serwisem. 
            </p>
            <img src="@/assets/imgs/welcome.svg" alt="" style="display: block; width: 100%; max-width: 720px; margin: 45px auto;">
        </div>
    </Layout>
</template>

<script>
export default {
    name: "Home",

    data(){
        return {
        }
    }
};
</script>

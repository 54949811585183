export default {
    
    namespaced: true,

    state: () => ({
        tamer_version: null,
        checked_for_updates: false,
        update_available: false,
        update_notes: null
    }),

    getters: {
        version: state => state.tamer_version ? state.tamer_version : '',
        newVersion: state => state.update_available && state.update_notes ? state.update_notes.new_version : false,
        changelog: state => state.update_notes ? state.update_notes.changelog : []
    },

    mutations: {
        setVersion(state, version){
            state.tamer_version = version;
        }
    },

    actions: {

        async checkForUpdates({state}){
            try{

                const r = await this._vm.$axios.$get("/_system/checkForUpdates");
                // console.log(r);

                state.checked_for_updates = true;

                if(r.data !== false){
                    state.update_available = true;
                    state.update_notes = r.data;
                }

            }
            catch(err){ console.error(err); }

            return state.update_available;
        },

        async updateTamer({state}){
            try{

                await this._vm.$axios.$post("/_system/update");

                state.tamer_version = state.update_notes.new_version;
                state.update_available = false;
                state.update_notes = null;

                return true;

            }
            catch(err){
                console.error(err);
                return err;
            }
        }

    }
}
<template>
    <div>
        <!-- KOMPONENT POZWALA NA ŁATWE WYSZUKIWANIE UŻYTKOWNIKÓW Z SYSTEMU -->
        <v-autocomplete
            :value="value"
            @input="_ => $emit('input', _)"
            outlined
            :loading="loading"
            :items="users"
            :search-input.sync="search_string"
            :hide-no-data="!searched_at_all"
            item-value="_id"
            item-text="name"
            label="Wyszukaj użytkownika"
            cache-items
            clearable
        >
            <template v-slot:item="{ item }">
                <div class="py-2">
                    <UserTile :user="item" />
                </div>
            </template>
            <template v-slot:selection="{ attrs, item }">
                <div class="pt-1">
                    <v-chip
                        v-bind="attrs"
                        color="secondary"
                        class="ma-0 mr-2"
                    >
                        <v-avatar
                            left 
                            v-if="item.profile_image.s3_file_url_128px != ''"
                        >
                            <v-img :src="item.profile_image.s3_file_url_128px"></v-img>
                        </v-avatar>
                        {{ item.name }}
                    </v-chip>
                </div>
            </template>
        </v-autocomplete>
    </div>    
</template>

<script>
export default {

    props: {
        filterStatus: {
            type: String,
            default: ''
        },
        filterRole: {
            type: String,
            default: ''
        },
        value: {
            type: String,
            default: ''
        }
    },
    
    data(){
        return {

            loading: false,
            users: [],
            search_string: '',
            searched_at_all: false,
            search_debouncer: null

        }
    },

    methods: {
        debounceSearch(){
            if(this.search_debouncer != null) clearTimeout(this.search_debouncer);
            this.search_debouncer = setTimeout(this.doSearch, 250);
        },
        async doSearch(force = false){
            if(this.loading) return;
            if(
                !force
                && (
                    !this.search_string 
                    || this.search_string.length < 3
                )
            ) return;
            this.loading = true;

            try{

                const r = await this.$axios.$get(
                    `/users/?filter_search=${this.search_string}${(this.filterRole != '' ? '&filter_role=' + this.filterRole : '')}${(this.filterStatus != '' ? '&filter_status=' + this.filterStatus : '')}`
                );
                
                this.users = r.users;
                this.searched_at_all = true;

            }
            catch(err){
                console.error(err);
            }

            this.loading = false;
            this.search_debouncer = null;
        }
    },

    watch: {
        search_string(){
            this.debounceSearch();
        }
    },

    async mounted(){
        if(!this.$store.state.initial_layout_set) return;
        if(this.value != ''){
            await this.doSearch(true);
            const ix = this.users.findIndex(item => item.id == this.value);
            if(ix == -1){
                // jeżeli nie pobrało akurat tego jednego usera, to pobieramy go sami
                try{
                    const r = await this.$axios.$get(`/users/${this.value}`);
                    this.users.push(r.user);
                }
                catch(err){}
            }
        }
    }
}
</script>
<template>
    <div 
        class="tiptap-wysiwyg"
        :class="{
            'tiptap-wysiwyg--fullscreen': enable_fullscreen 
        }"
        ref="wysiwyg"
    >
        <v-fade-transition mode="out-in">
            <div 
                class="tiptap-wysiwyg-container" 
                v-if="editor"
            >
                <div 
                    class="tiptap-wysiwyg-toolbar"
                    v-if="!disabled"
                >
                    <!-- HISTORIA - TO JEST ZAWSZE -->
                    <v-btn
                        text
                        class="tiptap-wysiwyg-toolbar__button"
                        :disabled="!editor.can().undo()"
                        @click="editor.chain().focus().undo().run()"
                        color="black"
                        title="Cofnij (Ctrl+Z)"
                    >
                        <v-icon size="24">mdi-undo</v-icon>
                    </v-btn>
                    <v-btn
                        text
                        class="tiptap-wysiwyg-toolbar__button"
                        :disabled="!editor.can().redo()"
                        @click="editor.chain().focus().redo().run()"
                        color="black"
                        title="Ponów (Ctrl+Shift+Z)"
                    >
                        <v-icon size="24">mdi-redo</v-icon>
                    </v-btn>
                    
                    <!-- HEADINGS -->
                    <template v-if="extensions.indexOf('heading') !== -1">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-select 
                            dense
                            hide-details
                            solo
                            color="black"
                            class="tiptap-wysiwyg-toolbar__select"
                            :items="[
                                {
                                    text: 'Zwykły tekst',
                                    value: 'p'
                                },
                                {
                                    text: 'Nagłówek 1',
                                    value: 'h1'
                                },
                                {
                                    text: 'Nagłówek 2',
                                    value: 'h2'
                                },
                                {
                                    text: 'Nagłówek 3',
                                    value: 'h3'
                                },
                                {
                                    text: 'Nagłówek 4',
                                    value: 'h4'
                                },
                                {
                                    text: 'Nagłówek 5',
                                    value: 'h5'
                                },
                                {
                                    text: 'Nagłówek 6',
                                    value: 'h6'
                                }
                            ]"
                            :value="textTypeActiveValue"
                            @change="onTextTypeSelect"
                        >
                            <template v-slot:selection="{ item }">
                                <div class="text-caption tiptap-wysiwyg-toolbar__selectsel">
                                    {{ item.text }}
                                </div>
                            </template>
                        </v-select>
                    </template>

                    <!-- TEXT BASIC FORMATS -->
                    <template v-if="extensions.some(el => (['bold', 'italic', 'underline', 'strike', 'code']).indexOf(el) !== -1)">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleBold().run()"
                            :color="editor.isActive('bold') ? 'primary' : 'black'"
                            title="Pogrubienie (Ctrl+B)"
                            v-if="extensions.indexOf('bold') !== -1"
                        >
                            <v-icon size="24">mdi-format-bold</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleItalic().run()"
                            :color="editor.isActive('italic') ? 'primary' : 'black'"
                            title="Pochylenie (Ctrl+I)"
                            v-if="extensions.indexOf('italic') !== -1"
                        >
                            <v-icon size="24">mdi-format-italic</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleUnderline().run()"
                            :color="editor.isActive('underline') ? 'primary' : 'black'"
                            title="Podkreślenie (Ctrl+U)"
                            v-if="extensions.indexOf('underline') !== -1"
                        >
                            <v-icon size="24">mdi-format-underline</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleStrike().run()"
                            :color="editor.isActive('strike') ? 'primary' : 'black'"
                            title="Przekreślenie (Ctrl+Shift+X)"
                            v-if="extensions.indexOf('strike') !== -1 && show_more_tools"
                        >
                            <div style="padding: 0px 2px;">
                                <v-icon size="20">mdi-format-strikethrough-variant</v-icon>
                            </div>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleCode().run()"
                            :color="editor.isActive('code') ? 'primary' : 'black'"
                            title="Formatuj jako kod (Ctrl+E)"
                            v-if="extensions.indexOf('code') !== -1 && show_more_tools"
                        >
                            <v-icon size="24">mdi-code-tags</v-icon>
                        </v-btn>
                    </template>

                    <!-- COLORS -->
                    <template v-if="extensions.some(el => (['color', 'highlight']).indexOf(el) !== -1) && show_more_tools">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click.stop="text_color_picker = true"
                            color="black"
                            title="Kolor tekstu"
                            v-if="extensions.indexOf('color') !== -1"
                            style="position: relative"
                        >
                            <v-icon size="24">mdi-format-color-text</v-icon>
                            <v-icon 
                                size="24" 
                                style="position: absolute; bottom: 0; left: 0;"
                                :color="editor.getAttributes('textStyle').color"
                            >mdi-color-helper</v-icon>
                        </v-btn>
                        <TipTapWysiwygColorPicker 
                            v-model="text_color_picker"
                            title="Wybierz kolor tekstu"
                            @color-pick="_ => {
                                if(_ == null){
                                    editor.chain().focus().unsetColor().run();
                                }
                                else{
                                    editor.chain().focus().setColor(_).run();
                                }
                            }"
                            v-if="extensions.indexOf('color') !== -1"
                        />
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click.stop="highlight_color_picker = true"
                            color="black"
                            title="Kolor zaznaczenia"
                            v-if="extensions.indexOf('highlight') !== -1"
                            style="position: relative"
                        >
                            <v-icon size="24">mdi-format-color-highlight</v-icon>
                        </v-btn>
                        <TipTapWysiwygColorPicker 
                            v-model="highlight_color_picker"
                            title="Wybierz kolor zaznaczenia"
                            @color-pick="_ => {
                                if(_ == null){
                                    editor.chain().focus().unsetHighlight().run();
                                }
                                else{
                                    editor.chain().focus().toggleHighlight({color: _}).run();
                                }
                            }"
                            v-if="extensions.indexOf('highlight') !== -1"
                        />
                    </template>

                    <!-- LINKS -->
                    <template v-if="extensions.indexOf('link') !== -1 && show_more_tools">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click.stop="openLinkEditDialog"
                            :color="editor.isActive('link') ? 'primary' : 'black'"
                            title="Wstaw/edytuj link"
                        >
                            <v-icon size="24">mdi-link</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click.stop="editor.chain().focus().extendMarkRange('link').unsetLink().run()"
                            :disabled="!editor.isActive('link')"
                            color="black"
                            title="Usuń link"
                        >
                            <v-icon size="24">mdi-link-off</v-icon>
                        </v-btn>
                        <v-dialog
                            persistent
                            scrollable
                            v-model="link_edit_dialog"
                            max-width="450px"
                        >
                            <v-card>
                                <v-card-title>
                                    Wstaw/edytuj link
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text class="pt-5" style="max-height: 65vh;">
                                    <v-text-field 
                                        outlined
                                        label="Adres odnośnika"
                                        v-model="link_edit_dialog_href"
                                        :rules="[
                                            v => {
                                                if(!v || v.length == 0) return 'Proszę podać adres odnośnika';
                                                if(v.length > 2047) return 'Maksymalna długość adresu to 2047 znaków';
                                                return true;
                                            }
                                        ]"
                                        ref="link_edit_dialog_href"
                                    />
                                    <v-checkbox 
                                        label="Otwórz odnośnik w nowej karcie"
                                        v-model="link_edit_dialog_target_blank"
                                        hide-details
                                        class="mt-0 mb-2"
                                    />
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="grey lighten-1"
                                        @click="link_edit_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="success"
                                        @click="onLinkDialogSubmit"
                                    >
                                        Zapisz zmiany
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </template>

                    <!-- TEXT ALIGN -->
                    <template v-if="extensions.indexOf('text-align') !== -1 && show_more_tools">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().setTextAlign('left').run()"
                            :color="editor.isActive({ textAlign: 'left' }) ? 'primary' : 'black'"
                            title="Wyrównaj do lewej (Ctrl+Shift+L)"
                        >
                            <v-icon size="24">mdi-format-align-left</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().setTextAlign('center').run()"
                            :color="editor.isActive({ textAlign: 'center' }) ? 'primary' : 'black'"
                            title="Wyrównaj do środka (Ctrl+Shift+E)"
                        >
                            <v-icon size="24">mdi-format-align-center</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().setTextAlign('right').run()"
                            :color="editor.isActive({ textAlign: 'right' }) ? 'primary' : 'black'"
                            title="Wyrównaj do prawej (Ctrl+Shift+R)"
                        >
                            <v-icon size="24">mdi-format-align-right</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().setTextAlign('justify').run()"
                            :color="editor.isActive({ textAlign: 'justify' }) ? 'primary' : 'black'"
                            title="Wyjustuj (Ctrl+Shift+J)"
                        >
                            <v-icon size="24">mdi-format-align-justify</v-icon>
                        </v-btn>
                    </template>

                    <!-- LISTS -->
                    <template v-if="extensions.some(el => (['bullet-list', 'ordered-list']).indexOf(el) !== -1) && show_more_tools">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleBulletList().run()"
                            :color="editor.isActive('bulletList') ? 'primary' : 'black'"
                            title="Lista nienumerowana (Ctrl+Shift+8)"
                            v-if="extensions.indexOf('bullet-list') !== -1"
                        >
                            <v-icon size="24">mdi-format-list-bulleted</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleOrderedList().run()"
                            :color="editor.isActive('orderedList') ? 'primary' : 'black'"
                            title="Lista nienumerowana (Ctrl+Shift+7)"
                            v-if="extensions.indexOf('ordered-list') !== -1"
                        >
                            <v-icon size="24">mdi-format-list-numbered</v-icon>
                        </v-btn>

                        <v-slide-x-transition>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().liftListItem('listItem').run()"
                                color="black"
                                :disabled="!editor.can().liftListItem('listItem')"
                                title="Przenieś element listy o poziom wyżej (Shift+Tab)"
                                v-if="editor.isActive('bulletList') || editor.isActive('orderedList')"
                            >
                                <v-icon size="24">mdi-format-indent-decrease</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                        <v-slide-x-transition>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().sinkListItem('listItem').run()"
                                color="black"
                                :disabled="!editor.can().sinkListItem('listItem')"
                                title="Przenieś element listy o poziom niżej (Tab)"
                                v-if="editor.isActive('bulletList') || editor.isActive('orderedList')"
                            >
                                <v-icon size="24">mdi-format-indent-increase</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                        
                    </template>

                    <!-- BLOCKQUOTE & CODEBLOCKS -->
                    <template v-if="extensions.some(el => (['blockquote', 'code-block']).indexOf(el) !== -1) && show_more_tools">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleBlockquote().run()"
                            :disabled="!editor.can().toggleBlockquote()"
                            :color="editor.isActive('blockquote') ? 'primary' : 'black'"
                            title="Cytat (Ctrl+Shift+B)"
                            v-if="extensions.indexOf('blockquote') !== -1"
                        >
                            <v-icon size="24">mdi-format-quote-close</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleCodeBlock().run()"
                            :disabled="!editor.can().toggleCodeBlock()"
                            :color="editor.isActive('codeBlock') ? 'primary' : 'black'"
                            title="Blok kodu (Ctrl+Alt+C)"
                            v-if="extensions.indexOf('code-block-lowlight') !== -1"
                        >
                            <v-icon size="24">mdi-code-greater-than-or-equal</v-icon>
                        </v-btn>
                    </template>

                    <!-- SUB- AND SUPERSCRIPT -->
                    <template v-if="extensions.some(el => (['superscript', 'subscript']).indexOf(el) !== -1) && show_more_tools">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleSuperscript().run()"
                            :color="editor.isActive('superscript') ? 'primary' : 'black'"
                            title="Indeks górny (Ctrl+.)"
                            v-if="extensions.indexOf('superscript') !== -1"
                        >
                            <v-icon size="24">mdi-format-superscript</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().toggleSubscript().run()"
                            :color="editor.isActive('subscript') ? 'primary' : 'black'"
                            title="Indeks dolny (Ctrl+,)"
                            v-if="extensions.indexOf('subscript') !== -1"
                        >
                            <v-icon size="24">mdi-format-subscript</v-icon>
                        </v-btn>
                    </template>

                    <!-- TABLE & BR & HR -->
                    <template v-if="extensions.some(el => (['hard-break', 'horizontal-rule', 'table']).indexOf(el) !== -1) && show_more_tools">
                        <div class="tiptap-wysiwyg-toolbar__separator"></div>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="table_create_dialog = true"
                            color="black"
                            title="Wstaw tabelę"
                            v-if="extensions.indexOf('table') !== -1"
                        >
                            <v-icon size="24">mdi-table</v-icon>
                        </v-btn>
                        <v-dialog
                            v-model="table_create_dialog"
                            max-width="450px"
                            scrollable
                        >
                            <v-card>
                                <v-card-title>
                                    Wstaw tabelę
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-text style="max-height: 60vh;" class="pt-5">
                                    <v-row>
                                        <v-col cols="6">
                                            <v-text-field
                                                type="number"
                                                label="Ilość wierszy"
                                                v-model="table_create_dialog_rows"
                                                outlined
                                                :rules="[
                                                    v => {
                                                        if(!v || v.length == 0) return 'To pole jest wymagane';
                                                        if(!(/^[0-9]+$/).test(v)) return 'Podaj liczbę całkowitą';
                                                        const tv = parseInt(v);
                                                        if(tv < 1 || tv > 16) return 'Podaj liczbę z zakresu 1-16';
                                                        return true;
                                                    }
                                                ]"
                                                ref="table_create_dialog_rows"
                                            ></v-text-field>
                                        </v-col>
                                        <v-col cols="6">
                                            <v-text-field
                                                type="number"
                                                label="Ilość kolumn"
                                                v-model="table_create_dialog_cols"
                                                outlined
                                                :rules="[
                                                    v => {
                                                        if(!v || v.length == 0) return 'To pole jest wymagane';
                                                        if(!(/^[0-9]+$/).test(v)) return 'Podaj liczbę całkowitą';
                                                        const tv = parseInt(v);
                                                        if(tv < 1 || tv > 16) return 'Podaj liczbę z zakresu 1-16';
                                                        return true;
                                                    }
                                                ]"
                                                ref="table_create_dialog_cols"
                                            ></v-text-field>
                                        </v-col>
                                    </v-row>

                                    <v-checkbox
                                        label="Pierwszy wiersz nagłówkiem tabeli"
                                        v-model="table_create_dialog_heading"
                                        class="mt-2 mb-8"
                                        hide-details 
                                    ></v-checkbox>

                                    <div class="text-caption" style="color: #ababab;">
                                        Ustawienia poszczególnych komórek dostępne są w menu pojawiającym się po zaznaczeniu jednej lub więcej komórek dodanej tabeli.
                                    </div>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="grey lighten-1"
                                        @click="table_create_dialog = false"
                                    >
                                        Anuluj
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="success"
                                        @click="onTableInsert"
                                    >
                                        Wstaw
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>


                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().setHardBreak().run()"
                            color="black"
                            title="Twardy enter (Ctrl+Enter)"
                            v-if="extensions.indexOf('hard-break') !== -1"
                        >
                            <v-icon size="24">mdi-wrap</v-icon>
                        </v-btn>
                        <v-btn
                            text
                            class="tiptap-wysiwyg-toolbar__button"
                            @click="editor.chain().focus().setHorizontalRule().run()"
                            color="black"
                            title="Pozioma linia odcinająca"
                            v-if="extensions.indexOf('horizontal-rule') !== -1"
                        >
                            <v-icon size="24">mdi-minus</v-icon>
                        </v-btn>
                    </template>

                    <!-- TOGGLE FULLSCREEN -->
                    <div class="tiptap-wysiwyg-toolbar__separator"></div>
                    <v-btn
                        v-if="show_more_tools"
                        text
                        class="tiptap-wysiwyg-toolbar__button"
                        @click="toggleFullscreen"
                        color="black"
                        :title="enable_fullscreen ? 'Zamknij tryb pełnoekranowy' : 'Przejdź w tryb pełnoekranowy'"
                    >
                        <v-icon size="24">{{ enable_fullscreen ? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all' }}</v-icon>
                    </v-btn>

                    <!-- TOGGLE MORE @SM -->
                    <v-btn
                        text
                        class="tiptap-wysiwyg-toolbar__button"
                        @click="show_more_tools = !show_more_tools"
                        color="black"
                        :title="show_more_tools ? 'Mniej narzędzi formatowania' : 'Więcej narzędzi formatowania'"
                        v-if="isSmSize"
                    >
                        <v-icon size="24">{{ show_more_tools ? 'mdi-unfold-less-horizontal' : 'mdi-unfold-more-horizontal' }}</v-icon>
                    </v-btn>


                </div>
                <div class="tiptap-wysiwyg-editor">
                    <div 
                        class="tiptap-wysiwyg__page"
                        :class="{
                            'tiptap-wysiwyg__page--focused': editor_focused,
                            'tiptap-wysiwyg__page--disabled': editor_width <= 700
                        }"
                    >
                        <TipTapEditorContent 
                            :editor="editor"
                        />
                    </div>
                </div>
                <div class="tiptap-wysiwyg-footer text-caption grey--text">
                    <div class="d-flex align-center flex-wrap">
                        <div class="d-flex align-center">
                            Made with 
                            <svg style="display: block; height: 16px; margin-left: 8px" viewBox="0 0 294 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M122.771 21.6745H115.891V12.9143H105.75V21.6745H100.751V29.2921H105.75V48.3361C105.702 55.5014 110.582 59.0484 117.938 58.7389C120.557 58.6437 122.414 58.12 123.437 57.7867L121.842 50.2405C121.342 50.3357 120.271 50.5738 119.319 50.5738C117.295 50.5738 115.891 49.812 115.891 47.003V29.2921H122.771V21.6745Z" fill="currentColor"></path>
                                <path d="M129.376 58.239H139.517V21.6745H129.376V58.239ZM134.471 16.9611C137.494 16.9611 139.97 14.652 139.97 11.8192C139.97 9.01026 137.494 6.70117 134.471 6.70117C131.471 6.70117 128.996 9.01026 128.996 11.8192C128.996 14.652 131.471 16.9611 134.471 16.9611Z" fill="currentColor"></path>
                                <path d="M147.641 71.9507H157.782V52.383H158.091C159.496 55.43 162.567 58.8341 168.47 58.8341C176.802 58.8341 183.301 52.2401 183.301 40.0044C183.301 27.4353 176.516 21.1984 168.494 21.1984C162.376 21.1984 159.448 24.8406 158.091 27.8162H157.639V21.6745H147.641V71.9507ZM157.568 39.9568C157.568 33.4342 160.329 29.2683 165.257 29.2683C170.279 29.2683 172.946 33.6246 172.946 39.9568C172.946 46.3365 170.232 50.7642 165.257 50.7642C160.377 50.7642 157.568 46.4793 157.568 39.9568Z" fill="currentColor"></path>
                                <path d="M209.51 21.6745H202.63V12.9143H192.49V21.6745H187.49V29.2921H192.49V48.3361C192.442 55.5014 197.322 59.0484 204.678 58.7389C207.296 58.6437 209.153 58.12 210.177 57.7867L208.582 50.2405C208.082 50.3357 207.011 50.5738 206.058 50.5738C204.035 50.5738 202.63 49.812 202.63 47.003V29.2921H209.51V21.6745Z" fill="currentColor"></path>
                                <path d="M229.197 58.8341C235.1 58.8341 238.171 55.43 239.576 52.383H240.004V58.239H250.002V21.6745H239.885V27.8162H239.576C238.219 24.8406 235.291 21.1984 229.173 21.1984C221.151 21.1984 214.366 27.4353 214.366 40.0044C214.366 52.2401 220.865 58.8341 229.197 58.8341ZM232.41 50.7642C227.435 50.7642 224.721 46.3365 224.721 39.9568C224.721 33.6246 227.388 29.2683 232.41 29.2683C237.338 29.2683 240.1 33.4342 240.1 39.9568C240.1 46.4793 237.291 50.7642 232.41 50.7642Z" fill="currentColor"></path>
                                <path d="M258.34 71.9507H268.481V52.383H268.79C270.195 55.43 273.266 58.8341 279.169 58.8341C287.501 58.8341 294 52.2401 294 40.0044C294 27.4353 287.216 21.1984 279.193 21.1984C273.075 21.1984 270.147 24.8406 268.79 27.8162H268.338V21.6745H258.34V71.9507ZM268.267 39.9568C268.267 33.4342 271.028 29.2683 275.956 29.2683C280.979 29.2683 283.645 33.6246 283.645 39.9568C283.645 46.3365 280.931 50.7642 275.956 50.7642C271.076 50.7642 268.267 46.4793 268.267 39.9568Z" fill="currentColor"></path>
                                <path d="M36.3979 0C30.1994 0 24.3628 1.54943 19.2542 4.2821H53.5415C48.4329 1.54943 42.5963 0 36.3979 0Z" fill="currentColor"></path>
                                <path d="M59.8522 8.56344H12.9435C11.3953 9.86933 9.95759 11.302 8.64635 12.8455H64.1494C62.8381 11.302 61.4004 9.86933 59.8522 8.56344Z" fill="currentColor"></path>
                                <path d="M67.2822 17.1283H5.51348C4.6575 18.4974 3.89002 19.9274 3.21906 21.4104H69.5766C68.9057 19.9274 68.1382 18.4974 67.2822 17.1283Z" fill="currentColor"></path>
                                <path d="M71.1957 25.6918H1.60004C1.1718 27.0853 0.824967 28.5146 0.5651 29.9739H72.2306C71.9707 28.5146 71.6239 27.0853 71.1957 25.6918Z" fill="currentColor"></path>
                                <path d="M72.7338 34.2567H0.0619353C0.0208355 34.9651 0 35.6791 0 36.3979C0 37.1165 0.0208297 37.8304 0.0619182 38.5387H72.7338C72.7749 37.8304 72.7957 37.1165 72.7957 36.3979C72.7957 35.6791 72.7749 34.9651 72.7338 34.2567Z" fill="currentColor"></path>
                                <path d="M72.2307 42.8215H0.565048C0.824902 44.2809 1.17172 45.7101 1.59995 47.1036H71.1957C71.624 45.7101 71.9708 44.2809 72.2307 42.8215Z" fill="currentColor"></path>
                                <path d="M69.5768 51.385H3.21892C3.88987 52.868 4.65733 54.298 5.51329 55.6671H67.2824C68.1384 54.298 68.9058 52.868 69.5768 51.385Z" fill="currentColor"></path>
                                <path d="M64.1496 59.9499H8.6461C9.95731 61.4934 11.395 62.9261 12.9431 64.232H59.8526C61.4007 62.9261 62.8384 61.4934 64.1496 59.9499Z" fill="currentColor"></path>
                                <path d="M53.542 68.5133H19.2537C24.3216 71.2244 30.1061 72.7709 36.2497 72.7954H36.546C42.6896 72.7709 48.4741 71.2244 53.542 68.5133Z" fill="currentColor"></path>
                            </svg>
                        </div>
                        <v-spacer></v-spacer>    
                        <div>
                            Znaków: {{ editor.storage.characterCount.characters() }}{{ (charactersLimit != null ? '/' + charactersLimit : '') }} &nbsp;|&nbsp; Wyrazów: {{ editor.storage.characterCount.words() }}
                        </div>
                    </div> 
                </div>

                <!-- MENU DLA TABELI -->
                <TipTapBubbleMenu
                    :editor="editor"
                    :tippy-options="{
                        duration: 100
                    }"
                    :should-show="bubbleMenuShouldShow"
                >
                    <v-card>
                        <v-card-text
                            class="pa-3"
                            elevation="4"
                            v-if="extensions.indexOf('table') !== -1 && !disabled"
                        >
                            <h6 class="text-caption text-uppercase mb-1 grey--text">
                                Narzędzia kolumn i wierszy
                            </h6>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().addColumnBefore().run()"
                                color="black"
                                title="Dodaj kolumnę z lewej"
                            >
                                <v-icon size="24">mdi-table-column-plus-before</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().addColumnAfter().run()"
                                color="black"
                                title="Dodaj kolumnę z prawej"
                            >
                                <v-icon size="24">mdi-table-column-plus-after</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().deleteColumn().run()"
                                color="black"
                                title="Usuń kolumnę"
                            >
                                <v-icon size="24">mdi-table-column-remove</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().addRowBefore().run()"
                                color="black"
                                title="Dodaj wiersz powyżej"
                            >
                                <v-icon size="24">mdi-table-row-plus-before</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().addRowAfter().run()"
                                color="black"
                                title="Dodaj wiersz poniżej"
                            >
                                <v-icon size="24">mdi-table-row-plus-after</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().deleteRow().run()"
                                color="black"
                                title="Usuń wiersz"
                            >
                                <v-icon size="24">mdi-table-row-remove</v-icon>
                            </v-btn>

                            <h6 class="text-caption text-uppercase mb-1 mt-3 grey--text">
                                Narzędzia komórek
                            </h6>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().mergeCells().run()"
                                :disabled="!editor.can().mergeCells()"
                                color="black"
                                title="Scal komórki"
                            >
                                <v-icon size="24">mdi-table-merge-cells</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().splitCell().run()"
                                :disabled="!editor.can().splitCell()"
                                color="black"
                                title="Scal komórki"
                            >
                                <v-icon size="24">mdi-table-split-cell</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('verticalAlign', 'top')
                                        .run();
                                }"
                                color="black"
                                title="Wyrównaj do góry"
                            >
                                <v-icon size="24">mdi-format-vertical-align-top</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('verticalAlign', 'middle')
                                        .run();
                                }"
                                color="black"
                                title="Wyrównaj do środka"
                            >
                                <v-icon size="24">mdi-format-vertical-align-center</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('verticalAlign', 'bottom')
                                        .run();
                                }"
                                color="black"
                                title="Wyrównaj do dołu"
                            >
                                <v-icon size="24">mdi-format-vertical-align-bottom</v-icon>
                            </v-btn>

                            <h6 class="text-caption text-uppercase mb-1 mt-3 grey--text">
                                Nagłówek tabeli
                            </h6>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().toggleHeaderRow().run()"
                                color="black"
                                title="Włącz/wyłącz nagłówek tabeli"
                            >
                                <v-icon size="24">mdi-table-headers-eye</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().toggleHeaderColumn().run()"
                                color="black"
                                title="Oznacz kolumnę jako nagłówkową"
                            >
                                <v-icon size="24">mdi-view-column</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().toggleHeaderCell().run()"
                                color="black"
                                title="Oznacz komórkę jako nagłówek"
                            >
                                <v-icon size="24">mdi-table-border</v-icon>
                            </v-btn>                          

                            <h6 class="text-caption text-uppercase mb-1 mt-3 grey--text">
                                Obramowanie
                            </h6>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('noBorderTop', false)
                                        .setCellAttribute('noBorderRight', false)
                                        .setCellAttribute('noBorderBottom', false)
                                        .setCellAttribute('noBorderLeft', false)
                                        .run();
                                }"
                                color="black"
                                title="Pełne obramowanie"
                            >
                                <v-icon size="24">mdi-border-all-variant</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('noBorderTop', true)
                                        .setCellAttribute('noBorderRight', true)
                                        .setCellAttribute('noBorderLeft', true)
                                        .setCellAttribute('noBorderBottom', false)
                                        .run();
                                }"
                                color="black"
                                title="Pełne obramowanie"
                            >
                                <v-icon size="24">mdi-border-bottom-variant</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('noBorderBottom', true)
                                        .setCellAttribute('noBorderRight', true)
                                        .setCellAttribute('noBorderLeft', true)
                                        .setCellAttribute('noBorderTop', false)
                                        .run();
                                }"
                                color="black"
                                title="Pełne obramowanie"
                            >
                                <v-icon size="24">mdi-border-top-variant</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('noBorderBottom', true)
                                        .setCellAttribute('noBorderTop', true)
                                        .setCellAttribute('noBorderLeft', true)
                                        .setCellAttribute('noBorderRight', false)
                                        .run();
                                }"
                                color="black"
                                title="Pełne obramowanie"
                            >
                                <v-icon size="24">mdi-border-right-variant</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('noBorderBottom', true)
                                        .setCellAttribute('noBorderTop', true)
                                        .setCellAttribute('noBorderRight', true)
                                        .setCellAttribute('noBorderLeft', false)
                                        .run();
                                }"
                                color="black"
                                title="Pełne obramowanie"
                            >
                                <v-icon size="24">mdi-border-left-variant</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="_ => {
                                    editor
                                        .chain()
                                        .focus()
                                        .setCellAttribute('noBorderBottom', true)
                                        .setCellAttribute('noBorderTop', true)
                                        .setCellAttribute('noBorderRight', true)
                                        .setCellAttribute('noBorderLeft', true)
                                        .run();
                                }"
                                color="black"
                                title="Pełne obramowanie"
                            >
                                <v-icon size="24">mdi-border-none-variant</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="table_cell_border_color_picker = true"
                                color="black"
                                title="Kolor obramowania"
                            >
                                <v-icon size="24">mdi-border-color</v-icon>
                            </v-btn>

                            <h6 class="text-caption text-uppercase mb-1 mt-3 grey--text">
                                Pozostałe
                            </h6>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="table_cell_background_color_picker = true"
                                color="black"
                                title="Kolor tła komórki"
                            >
                                <v-icon size="24">mdi-format-color-fill</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().fixTables().run()"
                                color="black"
                                title="Napraw tabelę"
                            >
                                <v-icon size="24">mdi-table-refresh</v-icon>
                            </v-btn>
                            <v-btn
                                text
                                class="tiptap-wysiwyg-toolbar__button"
                                @click="editor.chain().focus().deleteTable().run()"
                                color="black"
                                title="Usuń tabelę"
                            >
                                <v-icon size="24">mdi-table-remove</v-icon>
                            </v-btn>

                            <TipTapWysiwygColorPicker 
                                v-model="table_cell_background_color_picker"
                                title="Wybierz kolor tła komórki"
                                @color-pick="_ => {
                                    editor.chain().focus().setCellAttribute('backgroundColor', _).run();
                                }"
                            />
                            <TipTapWysiwygColorPicker 
                                v-model="table_cell_border_color_picker"
                                title="Wybierz kolor obramowania komórki"
                                @color-pick="_ => {
                                    editor.chain().focus().setCellAttribute('borderColor', _).run();
                                }"
                            />

                            

                        </v-card-text>
                    </v-card>
                </TipTapBubbleMenu>
            </div>
            <div 
                class="tiptap-wysiwyg-loader" 
                v-else
            >
                <v-progress-circular 
                    indeterminate
                    color="primary"
                    width="3"
                />
                <div class="text-caption mt-2 grey--text">
                    Trwa ładowanie edytora...
                </div>
            </div>
        </v-fade-transition>
    </div>
</template>

<script>
import TipTapWysiwygColorPicker from './TipTapWysiwygColorPicker.vue';
import { Editor, EditorContent, BubbleMenu } from '@tiptap/vue-2';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import CharacterCount from '@tiptap/extension-character-count';
import History from '@tiptap/extension-history';
import Heading from '@tiptap/extension-heading';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Strike from '@tiptap/extension-strike';
import Underline from '@tiptap/extension-underline';
import Typography from '@tiptap/extension-typography';
import Gapcursor from '@tiptap/extension-gapcursor';
import Dropcursor from '@tiptap/extension-dropcursor';
import Code from '@tiptap/extension-code';
import { Color } from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import TextAlign from '@tiptap/extension-text-align';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import ListItem from '@tiptap/extension-list-item';
import Blockquote from '@tiptap/extension-blockquote';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import Subscript from '@tiptap/extension-subscript';
import Superscript from '@tiptap/extension-superscript';
import Placeholder from '@tiptap/extension-placeholder';
import HardBreak from '@tiptap/extension-hard-break';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';

// css do kolorowania składni codeblock
import lowlight from 'lowlight'

export default {

    props: {
        value: {
            type: String,
            default: ''
        },
        charactersLimit: {
            type: [String, Number],
            default: null
        },
        extensions: {
            type: Array,
            default(){
                return [
                    'blockquote',
                    'bold',
                    'bullet-list',
                    'code',
                    'code-block-lowlight',
                    'color',
                    'hard-break',
                    'heading',
                    'highlight',
                    'history',
                    'horizontal-rule',
                    'italic',
                    'link',
                    'ordered-list',
                    'strike',
                    'subscript',
                    'superscript',
                    'table',
                    'text-align',
                    'underline'
                ]
            }
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    components: {
        TipTapEditorContent: EditorContent,
        TipTapWysiwygColorPicker,
        TipTapBubbleMenu: BubbleMenu
    },

    data(){
        return {
            editor: null,
            text_color_picker: false,
            highlight_color_picker: false,
            link_edit_dialog: false,
            link_edit_dialog_href: '',
            link_edit_dialog_target_blank: true,

            table_create_dialog: false,
            table_create_dialog_cols: 3,
            table_create_dialog_rows: 3,
            table_create_dialog_heading: true,

            enable_fullscreen: false,
            html_last_scroll_position: 0,

            bubble_menu_mode: '',
            table_cell_background_color_picker: false,
            table_cell_border_color_picker: false,

            editor_focused: false,
            editor_width: 2000,

            show_more_tools: true
        }
    },

    computed: {
        selectedExtensions(){
            const arr = [];

            if(this.extensions.includes('heading')) arr.push(Heading);
            if(this.extensions.includes('bold')) arr.push(Bold);
            if(this.extensions.includes('italic')) arr.push(Italic);
            if(this.extensions.includes('strike')) arr.push(Strike);
            if(this.extensions.includes('underline')) arr.push(Underline);
            if(this.extensions.includes('code')) arr.push(Code);
            if(this.extensions.includes('color')) arr.push(Color);
            if(this.extensions.includes('highlight')) arr.push(Highlight.configure({
                multicolor: true 
            }));
            if(this.extensions.includes('link')) arr.push(Link.configure({
                openOnClick: false,
                linkOnPaste: true,
                autolink: true,
                HTMLAttributes: {
                    rel: 'noopener'
                }
            }));
            if(this.extensions.includes('text-align')) arr.push(TextAlign.configure({
                types: ['heading', 'paragraph']
            }));
            if(this.extensions.includes('bullet-list')) arr.push(BulletList);
            if(this.extensions.includes('ordered-list')) arr.push(OrderedList);
            if(this.extensions.includes('blockquote')) arr.push(Blockquote);
            if(this.extensions.includes('code-block-lowlight')) arr.push(CodeBlockLowlight.configure({
                lowlight,
                defaultLanguage: 'plaintext'
            }));
            if(this.extensions.includes('superscript')) arr.push(Superscript);
            if(this.extensions.includes('subscript')) arr.push(Subscript);
            if(this.extensions.includes('hard-break')) arr.push(HardBreak);
            if(this.extensions.includes('horizontal-rule')) arr.push(HorizontalRule);
            if(this.extensions.includes('table')){
                arr.push(Table.configure({
                    resizable: true,
                    lastColumnResizable: false
                }));
                arr.push(TableRow);
                arr.push(TableCell.extend({
                    addAttributes(){
                        return {
                            ...this.parent?.(),
                            backgroundColor: {
                                default: null,
                                parseHTML: element => element.style.backgroundColor || null,
                                renderHTML: attrs => {
                                    if(attrs.backgroundColor != null){
                                        return {
                                            style: `background-color: ${attrs.backgroundColor}`
                                        }
                                    }
                                    return {}
                                }
                            },
                            borderColor: {
                                default: null,
                                parseHTML: element => element.style.borderColor || null,
                                renderHTML: attrs => {
                                    if(attrs.borderColor != null){
                                        return {
                                            style: `border-color: ${attrs.borderColor}`
                                        }
                                    }
                                    return {}
                                }
                            },
                            noBorderTop: {
                                default: false,
                                parseHTML: element => element.style.borderTopStyle == 'none',
                                renderHTML: attrs => {
                                    if(attrs.noBorderTop === true){
                                        return {
                                            style: `border-top-style: none`
                                        }
                                    }
                                    return {}
                                }
                            },
                            noBorderRight: {
                                default: false,
                                parseHTML: element => element.style.borderRightStyle == 'none',
                                renderHTML: attrs => {
                                    if(attrs.noBorderRight === true){
                                        return {
                                            style: `border-right-style: none`
                                        }
                                    }
                                    return {}
                                }
                            },
                            noBorderBottom: {
                                default: false,
                                parseHTML: element => element.style.borderBottomStyle == 'none',
                                renderHTML: attrs => {
                                    if(attrs.noBorderBottom === true){
                                        return {
                                            style: `border-bottom-style: none`
                                        }
                                    }
                                    return {}
                                }
                            },
                            noBorderLeft: {
                                default: false,
                                parseHTML: element => element.style.borderLeftStyle == 'none',
                                renderHTML: attrs => {
                                    if(attrs.noBorderLeft === true){
                                        return {
                                            style: `border-left-style: none`
                                        }
                                    }
                                    return {}
                                }
                            },
                            verticalAlign: {
                                default: 'top',
                                parseHTML: element => element.style.verticalAlign || 'top',
                                renderHTML: attrs => {
                                    return {
                                        style: `vertical-align: ${attrs.verticalAlign}`
                                    }
                                }
                            }
                        }
                    }
                }));
                arr.push(TableHeader.extend({
                    addAttributes(){
                        return {
                            ...this.parent?.(),
                            backgroundColor: {
                                default: null,
                                parseHTML: element => element.style.backgroundColor || null,
                                renderHTML: attrs => {
                                    if(attrs.backgroundColor != null){
                                        return {
                                            style: `background-color: ${attrs.backgroundColor}`
                                        }
                                    }
                                    return {}
                                }
                            },
                            borderColor: {
                                default: null,
                                parseHTML: element => element.style.borderColor || null,
                                renderHTML: attrs => {
                                    if(attrs.borderColor != null){
                                        return {
                                            style: `border-color: ${attrs.borderColor}`
                                        }
                                    }
                                    return {}
                                }
                            },
                            noBorderTop: {
                                default: false,
                                parseHTML: element => element.style.borderTopStyle == 'none',
                                renderHTML: attrs => {
                                    if(attrs.noBorderTop === true){
                                        return {
                                            style: `border-top-style: none`
                                        }
                                    }
                                    return {}
                                }
                            },
                            noBorderRight: {
                                default: false,
                                parseHTML: element => element.style.borderRightStyle == 'none',
                                renderHTML: attrs => {
                                    if(attrs.noBorderRight === true){
                                        return {
                                            style: `border-right-style: none`
                                        }
                                    }
                                    return {}
                                }
                            },
                            noBorderBottom: {
                                default: false,
                                parseHTML: element => element.style.borderBottomStyle == 'none',
                                renderHTML: attrs => {
                                    if(attrs.noBorderBottom === true){
                                        return {
                                            style: `border-bottom-style: none`
                                        }
                                    }
                                    return {}
                                }
                            },
                            noBorderLeft: {
                                default: false,
                                parseHTML: element => element.style.borderLeftStyle == 'none',
                                renderHTML: attrs => {
                                    if(attrs.noBorderLeft === true){
                                        return {
                                            style: `border-left-style: none`
                                        }
                                    }
                                    return {}
                                }
                            },
                            verticalAlign: {
                                default: 'top',
                                parseHTML: element => element.style.verticalAlign || 'top',
                                renderHTML: attrs => {
                                    return {
                                        style: `vertical-align: ${attrs.verticalAlign}`
                                    }
                                }
                            }
                        }
                    }
                }));
            } 

            // dodatkowe zależności
            if(
                this.extensions.includes('color')
                || this.extensions.includes('font-family')
            ){
                arr.unshift(TextStyle);
            }

            if(
                this.extensions.includes('bullet-list')
                || this.extensions.includes('ordered-list')
            ){
                arr.push(ListItem);
            }

            return arr;
        },
        textTypeActiveValue(){
            if(this.editor.isActive('paragraph')) return 'p';
            else if(this.editor.isActive('heading', { level: 1 })) return 'h1';
            else if(this.editor.isActive('heading', { level: 2 })) return 'h2';
            else if(this.editor.isActive('heading', { level: 3 })) return 'h3';
            else if(this.editor.isActive('heading', { level: 4 })) return 'h4';
            else if(this.editor.isActive('heading', { level: 5 })) return 'h5';
            else if(this.editor.isActive('heading', { level: 6 })) return 'h6';
            return null;
        },
        isSmSize(){
            return this.editor_width <= 600;
        }
    },

    watch: {
        value(str){
            if(this.editor.getHTML() === str) return; // to samo, nie ma po co odświeżać edytora
            this.editor.commands.setContent(str, false);
        },
        disabled(){
            if(this.editor) this.editor.setEditable(!this.disabled);
        }
    },

    methods: {
        onTextTypeSelect(ttype){
            if(ttype == 'p'){
                this.editor.chain().focus().setParagraph().run();
            }
            else{
                let HL = 1;
                if(ttype == 'h2') HL = 2;
                if(ttype == 'h3') HL = 3;
                if(ttype == 'h4') HL = 4;
                if(ttype == 'h5') HL = 5;
                if(ttype == 'h6') HL = 6;
                this.editor.chain().focus().toggleHeading({ level: HL }).run();
            }
        },
        openLinkEditDialog(){

            const previous = this.editor.getAttributes('link');

            if(previous.href) this.link_edit_dialog_href = previous.href;
            else this.link_edit_dialog_href = '';

            if(previous.target && previous.target == '_blank') this.link_edit_dialog_target_blank = true;
            else this.link_edit_dialog_target_blank = false;

            this.link_edit_dialog = true;
        },
        onLinkDialogSubmit(){
            if(!this.$refs.link_edit_dialog_href.validate(true)) return;

            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({ 
                    href: this.link_edit_dialog_href,
                    target: this.link_edit_dialog_target_blank ? '_blank' : '_self'
                })
                .run();

            this.link_edit_dialog_href = '';
            this.link_edit_dialog_target_blank = true;
            this.$nextTick(_ => {
                this.$refs.link_edit_dialog_href.resetValidation();
                this.link_edit_dialog = false;
            });
            
        },
        onTableInsert(){
            
            const a = [
                this.$refs.table_create_dialog_rows.validate(true),
                this.$refs.table_create_dialog_cols.validate(true)
            ];

            if(a.indexOf(false) !== -1) return;

            this.editor
                .chain()
                .focus()
                .insertTable({ 
                    rows: this.table_create_dialog_rows, 
                    cols: this.table_create_dialog_cols, 
                    withHeaderRow: this.table_create_dialog_heading 
                })
                .run();

            this.table_create_dialog_cols = 3;
            this.table_create_dialog_rows = 3;
            this.table_create_dialog_heading = true;

            this.table_create_dialog = false;

        },
        bubbleMenuShouldShow({editor, state, from, to}){
            const isTableBodyCell = editor.getAttributes('tableCell');
            const isTableHeaderCell = editor.getAttributes('tableHeader');

            if(isTableBodyCell.colspan && state.selectingCells$){
                this.bubble_menu_mode = 'table_body_cell';
                return true;
            }
            else if(isTableHeaderCell.colspan && state.selectingCells$){
                // console.log(isTableHeaderCell)
                this.bubble_menu_mode = 'table_header_cell';
                return true;
            }
            return false;
        },
        toggleFullscreen(){
            const html = document.querySelector('html');
            if(html){
                if(this.enable_fullscreen){
                    setTimeout(_ => {
                        html.scrollTop = this.html_last_scroll_position;
                    }, 10);
                }
                else{
                    this.html_last_scroll_position = html.scrollTop;
                }
            }
            this.enable_fullscreen = !this.enable_fullscreen;
            this.$nextTick(this.calcEditorSize);
        },
        calcEditorSize(){
            this.editor_width = this.$refs.wysiwyg.offsetWidth;
            if(this.isSmSize && this.show_more_tools) this.show_more_tools = false;
            else if(!this.isSmSize && !this.show_more_tools) this.show_more_tools = true;
        }
    },

    mounted(){
        this.editor = new Editor({
            content: this.value,
            extensions: [
                Document,
                Paragraph,
                Text,
                CharacterCount.configure({
                    limit: this.charactersLimit == null ? null : parseInt(this.charactersLimit)
                }),
                Gapcursor,
                Dropcursor,
                ...this.selectedExtensions,
                History,
                Typography,
                Placeholder.configure({
                    placeholder: 'Wprowadź treść...'
                })
            ],
            editable: !this.disabled,
            onUpdate: () => {
                // v-model do góry
                this.$emit('input', this.editor.getHTML());
            },
            onFocus: () => {
                this.editor_focused = true;
            },
            onBlur: () => {
                this.editor_focused = false;
            }
        });
        this.calcEditorSize();
        if(this.isSmSize) this.show_more_tools = false;
        window.addEventListener('resize', this.calcEditorSize, {passive: true});
    },

    beforeDestroy(){
        if(this.editor) this.editor.destroy();
        window.removeEventListener('resize', this.calcEditorSize, {passive: true});
    },

    metaInfo() {
        return {
            htmlAttrs: {
                class: this.enable_fullscreen ? 'ns' : ''
            }
        }
    }

}
</script>
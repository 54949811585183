import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import './assets/scss/index.scss';

/**************
 * VUE META *
***************/
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

/**************
 * AXIOS *
***************/
import axios from './axios';
Vue.use(axios);

/**************
 * MESSAGES *
***************/
import messages from './plugins/messages';
Vue.use(messages);

/**************
 * CLIPBOARD.js *
***************/
import VueClipboard from 'vue-clipboard2';
VueClipboard.config.autoSetContainer = true;
Vue.use(VueClipboard);

/**************
 * CROPPER.JS *
***************/
import 'cropperjs/dist/cropper.min.css';

/*********************
 * GLOBAL COMPONENTS *
**********************/
import Layout from './components/Layout';
Vue.component('Layout', Layout);

import UserSelect from './components/UserSelect';
Vue.component('UserSelect', UserSelect);

import UserTile from './components/UserTile';
Vue.component('UserTile', UserTile);

import TipTapWysiwyg from './components/TipTapWysiwyg';
Vue.component('TipTapWysiwyg', TipTapWysiwyg);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    beforeCreate: async () => {
        
        // TO NA SAMYM POCZĄTKU SIĘ DZIEJE - staramy się zalogować użytkownika z LS przed pierwszym procesowaniem przez router
        if(window.localStorage){

            const ad = JSON.parse(localStorage.getItem('auth'));
            if(
                ad
                && ad.token 
                && ad.token != ''
                && ad.refresh_token 
                && ad.refresh_token != ''
                && ad.token_exp_date 
                && !isNaN(ad.token_exp_date)
                && ad.token_exp_date > 0
                && ad.refresh_token_exp_date 
                && !isNaN(ad.refresh_token_exp_date)
                && ad.refresh_token_exp_date > 0
            ){
                // jeżeli refresh_token się nie przeterminował to można auth data podłożyć do store - axios już sobie odświeży co trzeba podczas pobierania danych użytkownika
                if(ad.refresh_token_exp_date * 1000 > Date.now() + 120000){
                    //refresh_token jest uznawany za ważny, jeżeli ma jeszcze chociaż 120s czasu życia
                    store.commit('auth/setAuthData', ad);
                    await store.dispatch('auth/fetchUserData');
                }
            }
        }
        
        // oznaczenie, że apka jest gotowa do pracy
        store.state.app_booted = true;

    },  
    render: h => h(App)
}).$mount('#app');

<template>
    <component 
        class="d-flex align-center text-decoration-none"
        :is="tag"
        :to="to"
    >
        <v-avatar 
            class="mr-2"
            :size="computedAvatarSize"
            color="primary"
        >
            <v-img 
                v-if="user.profile_image && user.profile_image != ''"
                :src="user.profile_image"
            />
            <div class="text-center white--text font-weight-medium" v-else>
                {{ $store.getters['auth/userInitials'](user) }}
            </div>
        </v-avatar>
        <div class="text-body-2">
            <div class="font-weight-medium" style="color: #434343">
                {{ user.name }}
                <span 
                    class="font-weight-regular" 
                    v-if="!hideNameDetails && $store.state.auth.user && $store.state.auth.user.id == user.id"
                >
                    (Ty)
                </span>
            </div>
            <div class="grey--text text-caption" style="margin-top: -4px;">
                <template v-if="detailsKey == 'role'">
                    {{ $store.state.USER_ROLE_LABELS[user.role] }}
                </template>
                <template v-else>
                    {{ user[detailsKey] }}
                </template>
            </div>
        </div>
    </component>
</template>

<script>
export default {

    computed: {
        computedAvatarSize(){
            return parseInt(this.avatarSize);
        }
    },
    
    props: {
        user: {
            type: Object,
            default(){
                return {};
            }
        },
        tag: {
            type: String,
            default: 'div'
        },
        to: {
            type: [String, Object],
            default: ''
        },
        detailsKey: {
            type: String,
            default: 'role'
        },
        avatarSize: {
            type: [Number, String],
            default: 42
        },
        hideNameDetails: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<template>
    <button
        class="tiptap-wysiwyg-colorpicker__button"
        @click="$emit('pick', color)"
        :style="{
            'background-color': color
        }"
        :class="{
            'tiptap-wysiwyg-colorpicker__button--padded': color == '#ffffff'
        }"
    ></button>
</template>

<script>
export default {
    
    props: {
        color: {
            type: String,
            required: true
        }
    }
}
</script>
/*******************************************************************************
 *
 * PLIK ZAWIERAJĄCY MESSAGES MAJĄCE ZASTĘPOWAĆ ZWROTKI Z API PO polu error_code
 * ORAZ HELPERY DO ICH POBIERANIA
 *
 *******************************************************************************/
const MESSAGES = {
    auth: {
        e201: "Nieprawidłowy login lub hasło",
        e202: "Konto zostało zablokowane z powodu wielu nieudanych prób logowania",
        e203: "Konto zostało zawieszone przez Administrację serwisu"
    },

    users: {
        e201: "Istnieje już w systemie konto powiązane z podanym adresem e-mail",

        e1002: "Podano nieprawidłowe aktualne hasło"
    },

    news: {
        e402: "Istnieje już inny wpis o takim tytule URL jak podano"
    },

    job_offers: {
        e402: "Istnieje już inna oferta pracy o takim tytule URL jak podano"
    },

    product_categories: {
        e402: "Istnieje już inna kategoria o takiej nazwie URL jak podano",
        e602: "Nie można usunąć kategorii, jeżeli dodano do niej produkty. Produkty należy najpierw usunąć lub przenieść do innej kategorii"
    },

    products: {
        e403: "Istnieje już inny produkt o takiej nazwie URL jak podano"
    },

    discount_codes: {
        e201: "Istnieje już taki kod w systemie",
        e402: "Istnieje już inny, taki sam kod w systemie"
    }
};

function getNestedObjProperty(obj, property) {
    let work_obj = obj;
    const splitted_property = property.split(".");

    for (let i = 0; i < splitted_property.length; i++) {
        if (typeof work_obj != "object" || work_obj[splitted_property[i]] == undefined) {
            return null;
        }

        work_obj = work_obj[splitted_property[i]];
    }

    return work_obj;
}

export function messageExists(path) {
    if (!path || path == "") return false;
    return getNestedObjProperty(MESSAGES, path) !== null;
}

export function getMessage(path) {
    if (!path || path == "") return "";
    const t = getNestedObjProperty(MESSAGES, path);
    return t === null ? path : t;
}
